import { createUi, clearUi, injectStyles } from './ui';
import getCurrentVariant from './common/getCurrentVariant';
import styles from './styles.scss';
import blockCountryPurchase, {
  fireLocationRuleEvent
} from './blockCountryPurchase';
import expandable, { expandableAllowed } from './expandable';
import applyCustomerTagRules from './customerTagRules';
import { fireEvent } from './utils';
import setupLocationSelection, {
  isLocationSelectionEnabled
} from './locationSelection';
import { getIncomingStockLinkHtml, showIncomingStock } from './incomingStock';
import atcButton from './common/atcButton';
import displayRegionalMsg from './templates/displayRegionalMsg.html';
import {
  isAllowedToOverSell,
  toggleAddToCartButton,
  waitForCondition
} from './common/commonUtils';
import { insertDrawerContainer } from './common/commonUi';
import { showAvailabilityDrawerLink } from './drawer';
import { getWidget } from './widget';
import { buildWithListContainer } from './uiElementBuilder';

export default function render(data, productIdentifier, parentContainer) {
  if (data.variantLocations && data.variantLocations.length > 0) {
    const settings = data.settings || {};

    if (settings.loggedInOnly && !window.inventoryInfo.customerLoggedIn) {
      clearUi();
      return;
    }
    const currentVariant = getCurrentVariant(
      data.variantLocations,
      productIdentifier,
      settings
    );
    if (currentVariant && !isAllowedToOverSell(currentVariant)) {
      blockCountryPurchase(
        currentVariant.inventoryLocations,
        data.settings,
        parentContainer
      );
    } else if (currentVariant && isAllowedToOverSell(currentVariant)) {
      let btn = null;
      waitForCondition(
        'atcButtonOverSell',
        function() {
          btn = atcButton.getAddToCartButton(settings, parentContainer);
          return btn;
        },
        function() {
          if (btn && atcButton.isBlockedByApp(btn)) {
            toggleAddToCartButton(btn, false, settings);
            fireLocationRuleEvent(false);
          }
        }
      );
    }
    applyCustomerTagRules(
      currentVariant.inventoryLocations,
      settings,
      parentContainer
    );
    if (window.inventoryInfo.api.applySelectedLocationRule) {
      window.inventoryInfo.api.applySelectedLocationRule(
        currentVariant,
        settings,
        parentContainer
      );
    }
    injectAllStyles(settings);
    if (settings.hideWidget) {
      createUi(settings, displayRegionalMsg, parentContainer);
      return;
    }

    if (settings.drawer) {
      showAvailabilityDrawerLink(
        currentVariant,
        data,
        productIdentifier,
        parentContainer
      );
      return;
    }
    if (settings.geo && settings.geo.enabled && settings.geo.enabled.apiKey) {
      window.inventoryInfo.geo.createInput(function(inputHtml) {
        return renderUi(
          buildWithListContainer(inputHtml, settings),
          settings,
          productIdentifier,
          parentContainer
        );
      }, settings.geo);

      return;
    }
    getWidget(data, productIdentifier, currentVariant).then(function(widget) {
      if (widget.html) {
        renderUi(
          widget.html,
          settings,
          productIdentifier,
          parentContainer
        ).then(function() {
          setupLocationSelection(settings, parentContainer);
        });
      } else if (showIncomingStock(currentVariant, settings)) {
        displayIncomingLink(currentVariant, settings, parentContainer);
      } else if (widget.clear) {
        clearUi();
      }
    });
  }
}

function renderUi(html, settings, productIdentifier, parentContainer) {
  return new Promise(function(resolve) {
    createUi(settings, html, parentContainer).then(function() {
      expandable(settings, productIdentifier, parentContainer);
      fireEvent(window.inventoryInfo.APP_RENDERED_EVENT);
      resolve();
    });
  });
}

function injectAllStyles(settings) {
  if (!window.inventoryInfo.hasStyles) {
    injectStyles(styles[0][1]);
    const overrides = getStyleOverride(settings);
    if (overrides) {
      injectStyles(overrides);
    }
    if (settings.css) {
      injectStyles(decodeURIComponent(settings.css));
    }
    window.inventoryInfo.hasStyles = true;
  }
}

function displayIncomingLink(currentVariant, settings, parentContainer) {
  injectAllStyles(settings);
  insertDrawerContainer(currentVariant);

  createUi(settings, getIncomingStockLinkHtml(settings), parentContainer);
}
function getStyleOverride(settings) {
  let overrides = '';
  if (settings.header === '') {
    overrides += '.iia-store-icon {display:none;}';
  }
  if (settings.hideLocationDetails) {
    overrides +=
      ' .iia-address, .iia-phone {display:none;} .iia-list-item {padding:7px;}';
  }
  if (settings.hideBorders) {
    overrides +=
      ' .iia-store-header, .iia-online-header, .iia-list {border-width:0px;} .iia-list > .iia-list-item ~ .iia-list-item {border-top-width:0px;}';
  }

  if (settings.qtyColor || settings.qtyBold) {
    overrides += ` .iia-qty {${settings.qtyBold ? 'font-weight:700;' : ''} ${
      settings.qtyColor ? 'color:' + settings.qtyColor + ';' : ''
    }}`;
  }

  if (settings.locationColor || settings.locationBold) {
    overrides += ` .iia-location {${
      settings.locationBold ? 'font-weight:700;' : ''
    } ${
      settings.locationColor ? 'color:' + settings.locationColor + ';' : ''
    }}`;
  }

  if (settings.infoColor || settings.infoBold) {
    overrides += ` .iia-info, .iia-note{${
      settings.infoBold ? 'font-weight:700;' : ''
    } ${settings.infoColor ? 'color:' + settings.infoColor + ';' : ''}}`;
  }

  if (expandableAllowed(settings)) {
    overrides +=
      '.iia-details-container {height:0;overflow:hidden;display: none;transition: height 350ms ease-in-out;}';
  }
  if (!isLocationSelectionEnabled(settings)) {
    overrides += ' .iia-location-selector {display:none;}';
  } else {
    overrides += ' .iia-location-info {margin-left:5px;}';
    overrides += ' .iia-list-item {display: flex;}';
  }
  return overrides;
}
